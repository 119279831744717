@import "src/client_customizations/scss/client_master.scss";

.EVsPage {

    .EVsSort {
        margin-bottom: 15px;
    
        @media(max-width:991px) {
            margin-top: 15px;
        }
    
        label {
            font-weight: $EVsSort-label-font-weight;
            font-size: $EVsSort-label-font-size;
            color: $EVsSort-label-color;
            letter-spacing: $EVsSort-label-letter-spacing;
            line-height: $EVsSort-label-line-height;
        }
    
        select {
            // background: $EVsSort-select-background;
            border: $EVsSort-select-border;
            border-radius: $EVsSort-select-border-radius;
            font-size: $EVsSort-select-font-size;
            color: $EVsSort-select-color;
            letter-spacing: $EVsSort-select-letter-spacing;
        }
    }
    
        .page-title {
            margin: 0 0 20px;
            font-family: 'Interstate', 'Open Sans';
            font-style: normal;
            font-weight: 700;
            font-size: 42px;
            line-height: 48px;
            /* identical to box height, or 133% */
    
            color: #333333;
        }
    
        .subtitle {
            margin: 24px auto 30px;
            font-family: 'Interstate', 'Open Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 32px;
            text-align: center;
            max-width: 900px;
        }
    
    .evsToggle {
        margin-bottom: 1rem;
        text-align: center;
    }
    
    .rangeToggle {
        text-align: center;
    }
    
    .renderToolTip {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .tooltipEvName {
        color: #0072CE;
        border-bottom: solid 5px #d9d9d9;
        font-size: 18px;
    }
    
    .tooltipEvName:hover {
        text-decoration: none;
    }
    
    .tooltipTextContainer {
        display: flex;
        flex-direction: column;
        border: solid 2px #F5F5F5;
        padding: 8px;
        text-decoration: none;
    }
    
    .tooltipDescription {
        display: flex;
        flex-direction: column;
    
        p {
            font-size: 18px;
            margin-bottom: 0px;
        }
    }
    
    .lead {
        padding-top: 12px;
    }
    
    .evsToggle {
        padding-bottom: 20px;
    }
    
    .mobileText {
        margin: 15px;
    }
    
    .evs-toggle-image {
        padding-right: 8px;
    }
    
    .toggle-btn {
        padding: 10px 40px;
        font-size: 18px;
        border-radius: 0;
        border: 0;
        color: $darkBlue;
        font-weight: 500;
        background-color: #fff;
        border: 1px solid $darkBlue;
        vertical-align: middle;
        margin-left: 2px;
        margin-right: 2px;
    
        .evs-toggle-image-hover {
            display: none;
        }
    
        &:hover {
            border-radius: 0;
            color: #fff;
            font-weight: 500;
            text-decoration: none;
            background-color: $darkBlue;
    
            .evs-toggle-image-passive {
                display: none;
            }
    
            .evs-toggle-image-hover {
                display: inline;
            }
        }
    }
    
    .toggle-btn-active {
        padding: 10px 40px;
        font-size: 18px;
        border-radius: 0;
        border: 0;
        color: #fff;
        font-weight: 500;
        background-color: $darkBlue;
        border: 1px solid $darkBlue;
        vertical-align: middle;
        margin-right: 2px;
        margin-left: 2px;
    }
    
    @media (min-width: 576px) {
        .EVsSort {
            float: right;
        }
    }
    
    .cost-vs-range-graph {
        .evs-toggle-image {
            padding-right: 8px;
        }
    
        .toggle-btn {
            padding: 10px 40px;
            font-size: 18px;
            border-radius: 0;
            border: 0;
            color: $darkBlue;
            font-weight: 500;
            background-color: #fff;
            border: 1px solid $darkBlue;
            vertical-align: middle;
            margin-left: 2px;
            margin-right: 2px;
    
            .toggle-image-hover {
                display: none;
            }
    
            &:hover {
                border-radius: 0;
                color: #fff;
                font-weight: 500;
                text-decoration: none;
                background-color: $darkBlue;
    
                .toggle-image-passive {
                    display: none;
                }
    
                .toggle-image-hover {
                    display: inline;
                }
            }
        }
    
        .toggle-btn-active {
            padding: 10px 40px;
            font-size: 18px;
            border-radius: 0;
            border: 0;
            color: #fff;
            font-weight: 500;
            background-color: $darkBlue;
            border: 1px solid $darkBlue;
            vertical-align: middle;
            margin-right: 2px;
            margin-left: 2px;
    
            .toggle-image-passive {
                display: none
            }
        }
    }
}