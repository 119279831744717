@import "src/client_customizations/scss/client_master.scss";

/* Apply yellow span underline for mobile views */
.Header {
	background-color: $Header-background-color;
	border-bottom: $Header-border-bottom;
	a {
		color:$Header-link-color;

		.header-logo {
			height: 98px;
			width: auto;
			max-width: 198px;
		}
	}
	.navbar {
		padding-top: 0;
		padding-bottom: 0;
		.navbar-brand {
			padding-top: 0;
			padding-bottom: 0;
			img {
				width: 198px;
			}
		}
		.navbar-collapse {
			a {
				span {
					font-family: 'Interstate', 'Open Sans';
					font-style: normal;
					font-weight: 400;
					font-size: 16px;
					line-height: 19px;
				}
			}
			a.active {
				span {
					color: #987000;
					text-decoration: none;
				}

                            &.is-not-home {
                                   span {
                                          color: #001689;
                                   }
                            }
			}

      .navbar-nav {
				padding: 0 0 0 80px;
				width: 100%;
        		align-items: center;				
				justify-content: space-between;
      }

      .dropdown-item {
        border-radius: 0;
      }
		}

    .dropdown-menu {
      top: 50px;
      border-radius: 3px;

      .dropdown-item {
				color: #001689;
        font-size: 13px;


        &.active,
        &:active {
          background-color: transparent;
          color: #987000;
        }
      }
    }
	}
	.nav-item {
		margin: 0px 0px 0px 5px;
	}
}
.skip-to-content-link {
	position: absolute;
	top: -1000px;
	left: -1000px;
	height: 1px;
	width: 1px;
	text-align: left;
	overflow: hidden;
}
a.skip-to-content-link {
	&:active {
		left: 75px;
		top: 20px;
		width: auto;
		height: auto;
		overflow: visible;
	}
	&:focus {
		left: 75px;
		top: 20px;
		width: auto;
		height: auto;
		overflow: visible;
	}
	&:hover {
		left: 75px;
		top: 20px;
		width: auto;
		height: auto;
		overflow: visible;
	}
}
.navbar-toggler {
	border-color: $Header-navbar-toggler-border-color;
	color:$Header-navbar-toggler-color;
	.navbar-toggler-icon {
		background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(241,92,42, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
	}
}

.language-toggle-container {
       position: relative;
       display: inline-block;

       .language-toggle {
              cursor: pointer;
              img {
                     width: 24px;
                     height: 24px;
              }
       }

       .caret {
              position: absolute;
              top: 50%;
              right: -13px;
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 5px 5px 0 5px;
              border-color: #333333 transparent transparent transparent;
              transform: translateY(-50%);
              transition: transform 0.2s ease-in-out;
       }

       .caret-down {
              transform: translateY(-50%) rotate(180deg);
       }

       .language-dropdown {
              position: absolute;
              top: 30px;
              right: -10px;
              z-index: 1;
              background-color: #ffffff;
              box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
              border-radius: 4px;
              padding: 8px;

              button {
                     background:transparent;
                     border:none;
                     color:#001689;
                     font-size:16px;
                     padding-left:8px;
                     line-height: 1em;
                     margin-bottom:10px;
                     font-weight:600;

                     &.active {
                            border-left:3px solid #001689;
                            padding-left:5px;
                     }
              }
       }
}


@media (min-width: 992px) {
	.Header {
		.navbar {
			padding: 0;
			.navbar-collapse {
				a {
					border-radius: 4px;
					font-weight: 700;
					letter-spacing: 0.6px;
					text-decoration: none;
				}
				a.active {
					font-weight: bold;
				}
			}
		}
	}
}
