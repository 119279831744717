

@font-face {
  font-family: 'Interstate';
  src: url("./assets/fonts/Interstate Light.otf")
      format("opentype");
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'Interstate';
  src: url("./assets/fonts/Interstate Light Italic.otf")
      format("opentype");
  font-style: italic;
  font-weight: 300;
}

@font-face {
  font-family: 'Interstate';
  src: url("./assets/fonts/Interstate Regular.otf")
      format("opentype");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'Interstate';
  src: url("./assets/fonts/Interstate Italic.otf")
      format("opentype");
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: 'Interstate';
  src: url("./assets/fonts/Interstate Bold.otf")
      format("opentype");
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'Interstate';
  src: url("./assets/fonts/Interstate Bold Italic.otf")
      format("opentype");
  font-style: italic;
  font-weight: 700;
}