@import "src/client_customizations/scss/client_master.scss";

.UsedEVsPage {
	.page-title {
			margin: 0 0 20px;
			font-family: 'Interstate', 'Open Sans';
			font-style: normal;
			font-weight: 700;
			font-size: 42px;
			line-height: 48px;
			/* identical to box height, or 133% */
	
			color: #333333;
		}
	
		.subtitle {
			margin: 24px auto 30px;
			font-family: 'Interstate', 'Open Sans';
			font-style: normal;
			font-weight: 400;
			font-size: 24px;
			line-height: 32px;
			text-align: center;
			max-width: 900px;
		}

	.EVsSort {
		margin-bottom: 15px;
		label {
			font-weight: $EVsSort-label-font-weight;
			font-size: $EVsSort-label-font-size;
			color: $EVsSort-label-color;
			letter-spacing:$EVsSort-label-letter-spacing;
			line-height: $EVsSort-label-line-height;
		}
		select {
			// background: $EVsSort-select-background;
			border: $EVsSort-select-border;
			border-radius:$EVsSort-select-border-radius;
			font-size: $EVsSort-select-font-size;
			color: $EVsSort-select-color;
			letter-spacing: $EVsSort-select-letter-spacing;
		}
	}
	#EVs {
		background-color: #F4F4F4;
	}
	@media (min-width: 576px) {
		.EVsSort {
			float: right;
		}
	}
}