$RateAdvisorChart-border: 1px solid #dadada !default;
$RateAdvisorChart-border-top: null !default;
$RateAdvisorChart-border-bottom: null !default;
$RateAdvisorChart-border-left: null !default;
$RateAdvisorChart-border-right: null !default;
$RateAdvisorChart-padding: 3em 2em !default;
$RateAdvisorChart-box-shadow: none !default;
$RateAdvisorChart-title-text-align: left !default;
$RateAdvisorChart-title-text-transform: none !default;
$RateAdvisorChart-title-font-size: 18px !default;
$RateAdvisorChart-background-color: #fff !default;
$RateAdvisorChart-title-font-family: Open Sans, Helvetica, sans-serif;

.RateAdvisorChart {
  // position: sticky;
  top: 5px;
  max-height: 100vh;
  overflow: hidden;
  background-color: $RateAdvisorChart-background-color;
  padding: $RateAdvisorChart-padding;
  box-shadow: $RateAdvisorChart-box-shadow;

  .title {
    text-align: $RateAdvisorChart-title-text-align;
    text-transform: $RateAdvisorChart-title-text-transform;
    font-size: $RateAdvisorChart-title-font-size;
    font-family: $RateAdvisorChart-title-font-family;
  }

  // Bootstrap's toggles and checkboxes line up poorly with text smaller than
  // 14px
  label {
    font-size: 14px;
  }

  .chart-controls {
    padding:50px 0 0;
  }

  .mr-auto {
    margin-right:0 !important;
  }

  .chart-data-toggle {
    margin-right:10px;

    span {
      font-size:14px;
      position:relative;
      top:-6px;
      padding:0 10px 0 5px;
    }
  }

  .rate-change-button {
    background: #001689;
    padding:15px;
    font-size:14px;
    white-space: nowrap;
    border:none;

    font-family: 'Interstate', 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    /* or 138% */

    display: flex;
    align-items: center;
    text-align: center;

    color: #FFFFFF;
  }
}
