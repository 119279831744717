@import "src/client_customizations/scss/client_master.scss"; 

.CompareVehicles {
		padding-top:0px;

		.bgHolder {
			width: 100%;
			height: 704px;
			position: absolute;
			top: 0;
			left: 0;

			background-color: #F4F4F4;

			z-index: -1;
		}

		section {
			max-width: 100%;
			background-color: transparent;
		}

		.titleContainer {
			padding: 2.5rem 0;

			h2 {
				font-family: 'Open Sans';
				font-style: normal;
				font-weight: 700;
				font-size: 36px;
				line-height: 48px;

				text-align: center;

				color: #333333;
			}

			p {				
				font-family: 'Open Sans';
				font-style: normal;
				font-weight: 400;
				font-size: 18px;
				line-height: 26px;

				text-align: center;

				color: #333333;
			}
		}

		.compared-vehicle-container {
			padding: 20px 0 80px;
		}

		.single-vehicle {
			padding-top: 50px;
			padding-bottom: 50px;
		}

		.gray {
			background-color: #F4F4F4;
		}

		.chartTitle {	
			margin-top: 0;

			font-family: 'Open Sans';
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 24px;
			letter-spacing: 0.75px;
			text-transform: uppercase;

			color: #2B2B2B;
		}

		.chartContainer {
			padding: 24px 25px 16px !important;

			.btn {
				margin: auto 0 5px !important;
				background: #FFFFFF !important;
				border: 1px solid #DBDBDB !important;
				
				font-family: 'Open Sans' !important;
				font-style: normal !important;
				font-weight: 400 !important;
				font-size: 16px !important;
				line-height: 32px !important;
				text-align: center !important;

				color: #205694 !important;
			}
		}

		
}

.ComparedVehicle {
	.input-label {
		margin: 0 0 10px;		

		font-family: 'Open Sans';
		font-style: normal;
		font-weight: 400;
		font-size: 12px;
		line-height: 16px;
		display: flex;
		align-items: center;
		text-transform: uppercase;

		color: #2A2A2A;
	}
}