

.MatchScoreOptions {
	.h2 {
		text-align: initial;
		font-family: 'Interstate', 'Open Sans';
		font-style: normal;
		font-weight: 400;
		font-size: 18px;
		line-height: 22px;
	}
}