@import "src/client_customizations/scss/client_master.scss";

.RenderItem {
	.incentive-label-select {
		text-align: start;
		font-family: 'Interstate', 'Open Sans';
		font-style: normal;
		font-weight: 400;
		font-size: 12px;
		line-height: 14px;
		text-transform: uppercase;
	}
}

.MatchScoreOptions {
	.h2 {
		text-align: initial;
		font-family: 'Interstate', 'Open Sans';
		font-style: normal;
		font-weight: 400;
		font-size: 18px;
		line-height: 22px;
	}
}
