.label-select {  
  margin: 0 0 10px !important;

  font-family: 'Interstate', 'Open Sans' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  display: flex !important;
  align-items: center !important;
  text-transform: uppercase !important;

  color: #000000 !important;
}

.large {
  height: 42px;
  border: 0.625828px solid rgba(102, 99, 102, 0.66);
  border-radius: 0;
}