@import "src/client_customizations/scss/client_master.scss";

.SelectedCard {
  padding: 1em;
  text-align: center;
  text-transform: uppercase;
  background: #FFFFFF;
  border: 2px solid #001689;

  min-width: 230px;

  position: absolute;
  bottom: 42px;

  &.isPartner {
    border: 2px solid #58B947;
  }

  .contact-dealer {
    a {      
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 22px;


      color: #001689;
    }
  }

  .website-dealer {
    margin: auto auto 0;

    a {      
      font-family: 'Interstate', 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;


      color: #000000;
    }
  }
}

.SelectedCard h5 {
  font-family: 'Interstate', 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;

  text-align: center;

  color: #000000;
}

.SelectedCard .address-container {
  font-family: 'Interstate', 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;

  text-align: center;

  color: #666366;
}

.SelectedCard .oem-image {
  max-width: 150px;
  margin-bottom: 1.5em;
}
