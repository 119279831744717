@import "src/client_customizations/scss/client_master.scss";
.Footer {
	background-image: linear-gradient(#2f75b0, #315b97);


	.itemList {

		margin: 10px;
		p {			
			font-family: 'Open Sans';
			font-style: normal;
			font-weight: normal;
			font-size: 13px;
			line-height: 16px;

			letter-spacing: 0.15px;

			color: #ffffff;
			&:hover {
				font-weight: 600;
			}
		} 
	}

	.centered {
		display: flex;
		justify-content: center;
	}
	.social {
		margin: 0px 10px 30px 10px;
		img {
				height: 30px;
				width: 30px;
			}
	}
	

	.smallLogo {
		display: flex;
		justify-content: center;
		margin: 24px 0;

		img {			
			height: 77px;
			width: 235px;
		}
	}

	.copyright {
		font-family: 'Open Sans';
		font-style: normal;
		font-weight: 400;
		font-size: 13px;
		line-height: 16px;

		letter-spacing: 0.15px;

		color: #000000;
	}

	.institutionLinks {
		margin: 16px 0;

		display: flex;
		justify-content: flex-start;
		align-items: center;

		a {
			margin: 0 0 0 6px;

			font-family: 'Open Sans';
			font-style: normal;
			font-weight: 600;
			font-size: 12px;
			line-height: 16px;
			
			letter-spacing: 0.15px;
			
			color: #000000;

			p {
				margin: 0;
			}
		}
			
		a:first-child {
			margin: 0px;
		}
			
		&:first-child {
			margin: 0px;
		}
	}

	.disclaimer {
		text-align: center;
		font-family: 'Open Sans';
		font-style: normal;
		font-weight: 400;
		font-size: 12px;
		line-height: 16px;
		
		letter-spacing: 0.15px;
		
		color: #ffffff;
	}

       .disclaimer_container {
              text-align: center;

              div {
                     background-color:transparent !important;

                     img {
                            max-width: 100%;
                            margin:0 0 25px;
                     }

                     p {
                            color:white;
                     }
              }
       }
}