@import "src/client_customizations/scss/client_master.scss";


#VehicleTypeFilter {
    .filter-btns {
        display: inline;
    }
    .ev-filter-btn {
        text-transform: uppercase;
        font-size: 16px;
        box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
        border: none;
        padding: 20px 0px;
        cursor: pointer;
        width: 50%;
        outline: none;
	    margin-bottom: 10%;
    }
    .buttonActiveStyle {
        background-color: #001689;
        color: white;
    }
    .buttonInactiveStyle {
        color: black;
        background-color: white;
    }
   
}
 @media (max-width: 550px) {
     .filter-btns {
        display: inline;
    }
    .ev-filter-btn {
        text-transform: uppercase;
        font-size: 16px;
        box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
        border: none;
        padding: 20px 40px;
        cursor: pointer;
        width: 50%;
        outline: none;
	    margin-bottom: 10%;
    }
    .buttonActiveStyle {
        background-color: #001689;
        color: white;
    }
    .buttonInactiveStyle {
        color: black;
        background-color: white;
    }
}