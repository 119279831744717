@import '../../scss/client_master.scss';

$InputPanel-border: 1px solid #ccc !default;
$InputPanel-border-top: null !default;
$InputPanel-border-bottom: null !default;
$InputPanel-border-left: null !default;
$InputPanel-border-right: null !default;
$InputPanel-padding: 26px !default;
$InputPanel-box-shadow: 0px 20px 41px rgba(0, 0, 0, 0.1) !default;
$InputPanel-background-color: #fff !default;
$InputPanel-heading-margin-bottom: 0 !default; 
$InputPanel-heading-title-font-size: 15px !default;
$InputPanel-heading-title-font-weight: 400 !default;
$InputPanel-heading-title-font-family: 'Interstate', "Open Sans", Helvetica, sans-serif !default;

.InputPanel {
  background-color: $InputPanel-background-color;

  padding: $InputPanel-padding;

  box-shadow: $InputPanel-box-shadow;

  .heading {
    display: flex;
    align-items: center;
    margin-bottom: $InputPanel-heading-margin-bottom;

    .title {
      flex-grow: 1;
      font-size: $InputPanel-heading-title-font-size;
      font-weight: $InputPanel-heading-title-font-weight;
      font-family: $InputPanel-heading-title-font-family;
      line-height: 22px;
    }

    .btn-link {
      font-size: 22px;
      padding-bottom: 10px;
      color: #212529;

      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
  }
}
