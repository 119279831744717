$RateComparisonBanner-background-color: #ffffff !default;
$RateComparisonBanner-color: #231F20 !default;
$RateComparisonBanner-font-size: 18px !default;
$RateComparisonBanner-highlight-text-color: #617E25 !default;

.RateComparisonBanner {
  text-align: left;
  background-color: $RateComparisonBanner-background-color;
  padding: 0.5em;
  color: $RateComparisonBanner-color;
  font-size: $RateComparisonBanner-font-size;
  padding:15px 25px;
  font-weight: 600;

  .highlight {
    color: $RateComparisonBanner-highlight-text-color;
  }
}
