@import "src/client_customizations/scss/client_master.scss"; 

.ComparedCarDetails {
  margin: 1.6rem 0;
  text-align: center;
  .btn {
    background: #FFFFFF;
    border: 1px solid #DBDBDB;
    
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;
    text-align: center;

    color: #2C61AE;

    margin: 40px 0 0;
  }

  .title {
    font-weight: 500;
    font-size: 0.8rem;
    color: $darkGray;
    line-height: inherit;
    margin-bottom: 0;
    text-transform: uppercase;
  }

  p {
    margin-bottom: 0;
  }
  .afterIncentives 
  { .DetailColumn {
    border-top: 1px solid #dbdbdb;
  }}
  .row {
    display: flex;
    justify-content: center;
  
    .DetailColumn {
      top: 0.4rem;
      padding: 1rem 0;
      border-bottom: 1px solid #dbdbdb;
      text-align: center;
    }

    .title {      
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      text-transform: uppercase;

      align-items: center;
      text-align: center;

      color: #6D6F74;
    }

    .h5 {      
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;

      align-items: center;
      text-align: center;

      color: #000000;
    }
  }

  .afterIncentives {
    .h5 {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 48px;
      text-align: center;
      
      color: #000000;
    }
  }

  .lastRow {
    .DetailColumn {
      border-bottom: none;
    }
  }

  .h5 {
    font-size: 24px;
    font-weight: 600;
    color: $gray;
  }
}
