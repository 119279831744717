@import "src/client_customizations/scss/client_master.scss";

.DealerTabbedMaps {
  background-color: #F4F4F4;
  padding: 39px 0;

  .titleContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .dealerTitle {
    margin: 0;

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 48px;
    
    display: flex;
    align-items: center;
    text-align: center;
    
    color: #333333;
  }

  .dealerSubtitle {
    margin: 6px 0 52px;

    max-width: 730px;

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    
    text-align: center;
    
    color: #333333;

  }
   .map-container {
    position: relative;
    height: 552px;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
}

  .tabbed-maps-panel {
    // width: 1139px;
    // height: 552px;
    padding: 20px;
    border-radius: 3px;
    background: #FFFFFF;
    border: 1px solid #DCDCDC;
    box-shadow: 0 2px 5px 0px rgba(0,0,0, 0.15);
  }
  .nav-tabs {
    border-radius: 3px 3px 0 0;

    background-color: #ffffff;
    padding: 0 1.5rem;

    .nav-item {
      margin-bottom: 0;
    }

    .nav-link {
      cursor: pointer;
      border-radius: 0;
      padding: 1.5rem 0;
      font-weight: 400;
      margin: 0 1rem;
      border: none;
      font-size: 18px;
    }

    .nav-item:first-child .nav-link {
      margin-left: 0;
    }

    .nav-link.active,
    .nav-item.show .nav-link,
    .nav-link:hover,
    .nav-link:focus {
      color: $blue;
        box-shadow: inset 0 -5px 0 0 $blue;
    }

  }
  .titleContainer {    
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .title {
    margin: 0 auto;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 48px;
    
    display: flex;
    align-items: center;
    text-align: center;
    
    color: #333333;

  }

  .subtitle {
    max-width: 730px;

    margin: 6px auto 50px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;

    text-align: center;

    color: #333333;
  }

  .ZipcodeInputWithButton {
    button, input, select, .input-group {
      height: 48px;
    }
  }

  .tableHeader {
    margin: 48px 0 8px;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;

    p {
      margin: 0 auto 0 0;
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 26px;


      color: #000000;
    }
  }
  table {
    margin: 20px 5px 0px 10px;
    width: 99%;
    // flex-shrink: 1;
    tr {
        padding: 5px;
      &.white {
        background: white;
      }
      th {
        padding: 5px;
      }
      td {
        border: 1px solid darkgray;
        padding: 5px;
      }
    }
  }
}
