$color_1: #001689;
$color_2: #73767B;
$color_3: #f15c2a;
$color_4: #fff;
$color_5: #2B2B2B;
$font_family_1: "Open Sans", Helvetica, sans-serif;
$background_color_1: #fff;
$background_color_2: white;
$background_color_3: #f2f2f2;
$background_color_4: #001689;
$background_color_5: #f3f4f4;
$background_color_6: #001689;
$background_color_7: #000000;
$border_color_1: transparent;
$border_bottom_color_1: rgba(0, 0, 0, 0.25);
$border_bottom_color_2: #fff;

body {
	background-color: $background_color_1;
	font-family: $font_family_1;
	font-size: 12px;
}
h1 {
	font-size: 24px;
}
.h1 {
	font-size: 24px;
}
h2 {
	font-size: 18px;
}
.h2 {
	font-size: 18px;
}
.page-title {
	font-weight: 700;
}
.modal-title {
	font-size: 24px;
	font-weight: 700;
}
@media (min-width: 768px) {
	.modal-content {
		width: 100%;
	}
}
.modal {
	left: 0;
}
h3 {
	font-size: 16px;
}
.h3 {
	font-size: 16px;
}
h4 {
	font-size: 12px;
}
.h4 {
	font-size: 12px;
}
h5 {
	font-size: 10px;
}
.h5 {
	font-size: 10px;
}
.lead {
	font-size: 18px;
	font-weight: 400;
}
a {
	color: $color_1;
}
table {
	table-layout:fixed;
	tbody {
		th {
			font-weight: normal;
		}
		tr {
			th {
				&:first-child {
					text-align: left;
				}
				word-break: break-all;
			}
		}
	}
	tfoot {
		tr {
			th {
				&:first-child {
					text-align: left;
				}
				word-break: break-all;
			}
		}
	}
}
table.table-bordered {
	tfoot {
		td {
			font-weight: bold;
			border-top: 2px solid #dee2e6;
		}
		th {
			font-weight: bold;
			border-top: 2px solid #dee2e6;
		}
	}
	th {
		word-break: break-all;
	}
}
button.btn-sm {
	font-size: 80%;
}
 .MatchScoreOptions {
    p.h2 {
      margin-bottom: 25px;
    }

    &.input-well {
      .btn {
		background-color: $background_color_1;
		color: $color_1;
		display: inline-block;
		border: 1px solid #dbdbdb;
		font-weight: 600;
		font-size: 13px;
		height: 40px;

      }
    }
  }
.input-well {
	background-color: $background_color_2;
	padding: 25px;
	border-radius: 4px;
	border-radius: 0;
	.form-group {
		margin-bottom: 1.5rem;
		p {
			margin-bottom: 1rem;
		}
	}
	form {
		.form-group {
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	label {
		font-weight: 400;
		font-size: 12px;
		color: $color_5;
		text-transform: uppercase;
		margin-bottom: 10px;
	}
	.label-style {
		font-weight: 400;
		font-size: 12px;
		color: $color_5;
		text-transform: uppercase;
		margin-bottom: 0;
	}
	.btn {
		background-color: $background_color_1;
		color: $background_color_6;
		display: inline-block;
		border: 1px solid #dbdbdb;
		font-weight: 400;
		font-size: 13px;
	}
	.btn.active {
		color: white;
		background-color: $background_color_6;
		border: none;
	}
	.btn.filter {
		border-radius: 0px;
		height: 48px;
		display: inline;
		font-size: 14px;
		font-weight: 400;
	}
	.btn-block-container {
			display: block;
			width: 100%;
			margin-bottom: 6px;
			text-align: left;
			.badge {
				display: inline-block;
    			padding: .25em .4em;
    			font-size: 75%;
    			font-weight: 700;
    			line-height: 1;
    			text-align: center;
    			white-space: nowrap;
    			vertical-align: baseline;
    			border-radius: .25rem;
    			transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
				img {
					width: 20px;
				}
			}
			.filter-label {

				display: flex;
				align-items: center;
				width: 100%;
				height: 40px;

				.name {
					display: inline-flex;
					white-space: nowrap;
				}
				.switch {
					position: absolute;
    				left: 70%;
					.react-switch-bg {
						width: 55px !important;
					}
				}
			}
			.filter-switch {
				display: flex;
			}
			.first {
				margin-bottom: 10px;
			}
			
	}
	
	.btn-grid-container {
		display: flex;
		flex-wrap: wrap;
		button {
			width: 48%;
			margin: 2%;
			&:nth-of-type(2n) {
				margin-right: 0;
			}
			&:nth-of-type(2n +1) {
				margin-left: 0;
					}
		}
	}
	.btn-ae {
		padding: 6px 12px;
		border-radius: 0;
		border: 0;
		color: $color_4;
		font-weight: 600;
		background-color: $background_color_4;
	}
}
.btn-default {
	border: 1px solid #ced4da;
}
strong {
	font-weight: 500;
}
hr {
	border-top: 2px solid rgba(0, 0, 0, 0.8);
	margin: 0;
	padding: 0;
}
.arrow-up {
	top: calc((1rem - 1px) * -1);
	position: absolute;
	display: block;
	width: 1rem;
	height: 1rem;
	margin: 0 0.3rem;
	&:before {
		position: absolute;
		display: block;
		content: "";
		border-color: $border_color_1;
		border-style: solid;
		top: 0;
		border-width: 0 1rem 1rem 1rem;
		border-bottom-color: $border_bottom_color_1;
	}
	&:after {
		position: absolute;
		display: block;
		content: "";
		border-color: $border_color_1;
		border-style: solid;
		top: 1px;
		border-width: 0 1rem 1rem 1rem;
		border-bottom-color: $border_bottom_color_2;
	}
}
.background-grey {
	background-color: $background_color_5;
}
.bottom-thick-margin {
	border-bottom: 2px solid #000000;
}
.color-primary {
	color: $color_3;
}
.btn-ae {
	padding: 6px 12px;
	border-radius: 0;
	border: 0;
	color: $color_4;
	background-color: $background_color_6;
	&:hover {
		border-radius: 0;
		color: $color_4;
		text-decoration: none;
		background-color: $background_color_6;
	}
}
.btn-ae-custom {
	font-size: 15px;
	padding: 6px 24px;
	border-radius: 0;
	border: 4px solid $color_1;
	color: $color_1;
	font-weight: 600;
	background-color: $background_color_1;
	&:hover {
		border-radius: 0;
		color: $color_1;
		font-weight: 600;
		text-decoration: none;
		background-color: $background_color_1;
	}
	
}
.btn-ae-custom-two {
	width: 100%;
	font-size: 15px;
	padding: 6px 24px;
	border-radius: 0;
	border: 4px solid $color_1;
	color: $color_4;
	font-weight: 600;
	background-color: $background_color_6;
	&:hover {
		border-radius: 0;
		color: $color_4;
		font-weight: 600;
		text-decoration: none;
		background-color: $background_color_6;
	}
	
}
@media (max-width: 1200px) and (min-width: 768px) {
	.btn-ae-custom, .btn-ae-custom-two {
		padding: 6px 5px;
	}
}

.btn-ae-custom-three {
	font-size: 16px;
	padding: 10px 25px;
	border-radius: 0;
	border: 1px solid lightgray;
	color: $color_1;
	font-weight: 400;
	background-color: white;
	&:hover {
		border-radius: 0;
		color: $color_1;
		font-weight: 600;
		text-decoration: none;
		background-color: $background_color_1;
	}
}
.btn-ae-custom-four {
	font-size: 16px;
	color: $color_5;
	font-weight: 400;
	border: none;
	background-color: inherit;
	cursor: pointer;
}
.btn-ae-custom-five {
	font-size: 15px;
	padding: 6px 24px;
	border-radius: 0;
	border: 4px solid $color_1;
	color: $color_4;
	font-weight: 400;
	background-color: $background_color_6;
	&:hover {
		border-radius: 0;
		color: $color_4;
		font-weight: 400;
		text-decoration: none;
		background-color: $background_color_6;
	}
	
}
.left-margin {
	margin-left: 20px;
}
.right-margin {
	margin-right: 20px
}
.PageLayout {
	a.btn-ae {
		&:hover {
			border-radius: 0;
			color: $color_4;
			font-weight: 600;
			text-decoration: none;
			background-color: $background_color_6;
		}
	}
}
a.btn-ae {
	&:hover {
		border-radius: 0;
		color: $color_4;
		font-weight: 600;
		text-decoration: none;
		background-color: $background_color_6;
	}
}
#vehicle-sort-dropdown {
    background-position: 95%;
    background-size: .5em .5em;
    background-repeat: no-repeat;
    background-color: #fff;
}
.mb-0 {
	margin-top: 5px;
	color: $color_2;
}
.bottom-margin {
	margin-bottom: 40px;
}
#select-vehicle-age-filter {
    appearance: none;
    background: url(../../assets/images/icons/up-down-arrow.svg);
    background-position: calc(100% - .5rem), 100% 0;
    background-size:  .75em .75em;
    background-repeat: no-repeat;
    &:focus {
        box-shadow: none;
        outline: 0;
    }
}
.map-header {
	display: flex;
	flex-direction: column;
	margin-top: 30px;
}
.map-header-title {
	font-weight: 700;
	font-size: 14px;
	display: flex;
	flex-direction: column;
	margin-top: 20px;
}
.wide {
	width: 100%;
}

.graph-disclaimer {
	margin: 5px;
}
 .btn-arrow {
 	background: none;
 	border: none;
 	color: $color_1;
 	font-weight: 600;
 	font-size: 12px;
 }
 .selected-subText {
	margin-top: 10px;
 }


@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	/* IE10+ CSS */
	.select-ae {
		background-size: 0;
	}
 }
