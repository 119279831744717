@import "src/client_customizations/scss/client_master.scss";

.IncentivesPage {
  
  @media (max-width: 578px) {
    padding: 2rem 2rem !important;

    .ev-container-fluid {
      padding: 2rem 2rem !important;
    }

    .page-title {
      font-size: 34px;
    }

    .subtitle {
      font-size: 20px;
      line-height: 27px;
      font-weight: 300;
    }
  }

	padding: 2rem 8rem !important;
  max-width: 100% !important;

  div {
    text-align: center;
  }

  .page-title {
    margin:0 0 20px;
    font-family: 'Interstate', 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 42px;
    line-height: 48px;
    /* identical to box height, or 133% */

    color: #333333;
  }

  .subtitle {
    margin: 24px auto 30px;
    font-family: 'Interstate', 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    max-width: 900px;
  }

  .button {
    margin: 0 auto;

    display: flex;
    justify-content: center;
    align-items: center;

    height: 48px;
    width: 220px;
    
    background: #001689;
    border-radius: 2px;
    font-family: 'Interstate', 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 24px;

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.15px;

    color: #FFFFFF;
  }
}
