@import "src/client_customizations/scss/client_master.scss";

a {
	text-decoration: none !important;
	a:hover {
		text-decoration: none !important;		
	}
}

section.container.homepage-jumbotron {
	display: flex;
	align-items: center;
}
.homepage-jumbotron {
	.big-promise {
		padding: $HomePage-big-promise-padding;
		background-color: $HomePage-big-promise-background-color;
		color: $HomePage-big-promise-color;
		text-shadow: $HomePage-big-promise-text-shadow;
		min-height: $HomePage-big-promise-min-height;
		width: 100%;
		position: relative;
	}
}
#link-card-buttons {
	margin-top: -7rem;
	padding: 3rem 1rem 1rem 1rem;
	background: transparent;
}
#why-choose-evs {
	padding: 0rem 1rem;
}
.big-promise-caption {
	top: 0;
	left: 0;
	position: absolute;
	padding: 1.5rem 1rem;
}
.gradient-top-left{
	width: 100%;
	height: 475px;
	position: absolute;

	
	background: linear-gradient(90deg, #000000 16.67%, rgba(196, 196, 196, 0) 100%);
}

.big-promise-text {	
	position: absolute;
	top: 105px;
	margin: 0 calc(50vw - 570px);

	display: flex;
	justify-content: center;

	div {
		width: 610px;

		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	h2 {
		font-family: 'Interstate', 'Open Sans';
		font-style: normal;
		font-weight: 700;
		font-size: 36px;
		line-height: 43px;
		text-align: center;
		
		color: #000000;
	}

	p {
		margin: 16px 0 24px;

		font-family: 'Interstate', 'Open Sans';
		font-style: normal;
		font-weight: 400;
		font-size: 18px;
		line-height: 22px;

		text-align: center;
		letter-spacing: 0.15px;

		color: #000000;
	}

	button {
				
		font-family: 'Interstate', 'Open Sans';
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 22px;

		display: flex;
		align-items: center;
		text-align: center;
		letter-spacing: 0.15px;

		padding: 15px 33px;

		color: #FFFFFF;

		background-color: #001689;
		border: none;
		border-radius: 2px;
	}

	
	@media (max-width: 1100px) {	
		width: 100%;
		max-width: 100%;

		margin: 0;

		display: flex;
		justify-content: center;
	}
}


#HomepageCards {
	width: 100%;
	height: 263px;

	display: flex;
	justify-content: center;
	align-items: center;

	background-color: #FFFFFF;

	position: relative;
	z-index: 1;

	.container {
		width: 1020px;
		height: 263px;
	
		display: flex;
		justify-content: center;
		align-items: center;		

	}

	.card-link {
		width: 255px;
		height: 263px;
		background: #FFFFFF;

		transition: all ease 0.3s;

		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

              &.first {
                     p {
                            max-width:140px;
                     }
              }

              &.fourth {
                     img {
                            position: relative;
                            top:5px;
                     }
              }

              &.ev-vehicles {
                     img {
                            margin: 0;
                            height:78px;
                     }
              }

              &.last {
                     img {
                            margin:15px 0;
                            position: relative;
                            top:5px;
                     }
              }

		&:hover {
			height: 296px;
			box-shadow: 0px 20px 41px rgba(0, 0, 0, 0.1);
		}

		p {
			font-family: 'Interstate', 'Open Sans';
			font-style: normal;
			font-weight: 400;
			font-size: 18px;
			line-height: 24px;

			color: #001689;

			margin: 20px 0 0;
			max-width: 162px;
			text-align: center;
		}
	}
	
	@media (max-width: 575px) {	
		height: auto;

		.container{
			height: auto;

			flex-direction: column;
		}

		.card-link {
			margin: 0 !important;
			width: 100%;
		}
	}
}

#HomepageZipCode {
       background-color: #001689;
       padding:50px 0;

       .container {
              display:flex;
              gap:50px;
       }

       .zipcode-content {
              h2 {
                     color:white;
                     font-size:25px;
                     line-height: 1.2em;
                     margin:0 0 20px;
              }

              p {
                     color:white;
                     font-size:16px;
                     line-height: 1.2em;
              }
       }

       .zipcode-form-container {
              background-color:white;
              min-width: 300px;
              padding:25px;
              border-radius: 5px;

              label {
                     color:#333;
                     font-size:11px;
                     line-height: 1em;
                     text-transform: uppercase;
                     font-weight: bold;
              }

              .zipcode-form-button {
                     display: block;
                     text-align: center;
                     border:none;
                     width:100%;
                     padding:15px;
                     color:#001689;
                     font-weight: bold;
                     font-size:15px;
                     border-radius: 5px;
                     line-height: 1em;
                     background-color: #FFC425;
                     margin:20px 0 0;
              }

              .disclaimer {
                     color:#333;
                     margin:10px 0 0;
                     font-size:12px;
                     line-height: 1.4em;
              }
       }
}

#HomepageIncentives {
       h3 {
              font-family: 'Open Sans';
              font-style: normal;
              font-weight: 700;
              font-size: 36px;
              line-height: 49px;
              text-align: center;
              letter-spacing: 0.15px;
              color: #000000;
       }

       p.lead {
              text-align: center;
       }
}