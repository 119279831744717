.chartBtn-ae {  
  background: #001689;

  font-family: 'Interstate', 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;

  display: flex;
  align-items: center;
  text-align: center;

  color: #FFFFFF;

  border-radius: 0;

  &:hover {
       color:white;
  }

}

.chartBtn-secondary {
  background: #FFFFFF;
  border: 1px solid rgba(102, 99, 102, 0.33);

  font-family: 'Interstate', 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;
  
  color: #000000;

  border-radius: 0;
}