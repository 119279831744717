h2.title {
  font-family: 'Interstate', 'Open Sans'  !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 24px !important;
  line-height: 28px !important;

  color: #000000 !important;
}

h2.subtitle {
 font-family: 'Interstate', 'Open Sans' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 24px !important;
  line-height: 29px !important;


  color: #000000 !important;
}

p.text {
  font-family: 'Interstate', 'Open Sans' !important;
  font-style: normal !important;
  font-weight: 300 !important;
  font-size: 16px !important;
  line-height: 20px !important;

  display: flex !important;
  align-items: center !important;

  color: #000000 !important;
}