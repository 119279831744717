@import "src/client_customizations/scss/client_master.scss";

.hcFilterControls {
    margin-top: 50px;

    &.mt {margin-top: 16px !important;}

    p.h2 {
        margin: 0 0 20px;

        font-family: 'Interstate','Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;

        color: #000000;
    }
    .socket-text {
        float: left;
    }
    .socket-image {
        float: right;
    }

    label {
        font-family: 'Interstate','Open Sans' !important;
        font-style: normal !important;
        font-weight: 400 !important;
        font-size: 12px !important;
        line-height: 14px !important;
        text-transform: uppercase !important;

        color: #000000 !important;

        margin: 0 0 8px !important;

    }

    .label-range.mb {
        margin-bottom: 16px !important;
    }

    .btn {
        height: 48px !important;
        border-radius: 0 !important;
    }

    .select-iid {
        border-radius: 0 !important;
    }
}

.filter-controls-disclaimer {
       margin:20px 0;

       p {
              font-size:14px;
       }

    .disclaimer-link {
        text-decoration: underline !important;
    }
}

#vehicle-sort-dropdown {
    border-radius: 0 !important;
}